/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Img from 'gatsby-image';
import { Button, makeStyles, Typography, Backdrop, Container } from '@material-ui/core';
// import { Carousel } from 'react-responsive-carousel';
import ImageGallery from 'react-image-gallery';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 0),
  },
  card: {
    height: 300,
    width: '100%',
  },
  cardContent: {
    borderRadius: 8,
  },
  cardButton: {
    boxShadow: '0 0 25px rgba(0, 0, 0, 0.14)',
    borderRadius: 40,
    backgroundColor: '#ffffff',
    height: 53,
    position: 'absolute',
    bottom: -22,
    minWidth: 140,
  },
  cardButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  text: {
    textTransform: 'capitalize',
  },
  imageGalleryWrapper: {
    padding: theme.spacing(6),
    backgroundColor: '#f6f7fa',
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      margin: '10%',
    },
  },
  imageGallery: {
    '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function ({ images }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const items = images
    .filter(ele => !ele.node.childImageSharp.fluid.originalName.match('home'))
    .map(ele => ({
      renderItem: () => (
        <div key="ele">
          <Img fluid={ele.node.childImageSharp.fluid} />
        </div>
      ),
      renderThumbInner: () => (
        <div key="ele">
          <Img fluid={ele.node.childImageSharp.fluid} />
        </div>
      ),
    }));

  console.log(items);

  return (
    <>
      <div className={classes.root}>
        <Button onClick={handleOpen} fullWidth disableRipple>
          <div className={classes.card}>
            <div>
              <Img fluid={images[0].node.childImageSharp.fluid} className={classes.cardContent} />
            </div>
          </div>
        </Button>

        <div className={classes.cardButtonWrapper}>
          <Button className={classes.cardButton} variant="contained" onClick={handleOpen}>
            <Typography variant="h5" className={classes.text}>
              {images[0].node.relativeDirectory}
            </Typography>
          </Button>
        </div>
      </div>
      {open && (
        <Backdrop open onClick={handleClose}>
          <Container maxWidth="lg">
            <div
              className={classes.imageGalleryWrapper}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {/* <Carousel centerMode={50} infiniteLoop showThumbs={false} showStatus={false}>
              {images.map(ele => (
                <div key="ele" >
                  <Img fluid={ele.node.childImageSharp.fluid} />
                </div>
              ))}
            </Carousel> */}
              <ImageGallery
                additionalClass={classes.imageGallery}
                showPlayButton={false}
                // showFullscreenButton={false}
                items={items}
              />
            </div>
          </Container>
        </Backdrop>
      )}
    </>
  );
}
