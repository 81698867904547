import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, Container } from '@material-ui/core';

import Header from './header';
import TextSections from './textSections';
import Form from './form';
import Socials from './socials';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  spacer: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      minHeight: 28,
    },
  },
}));

export default function () {
  const classes = useStyles();
  return (
    <div>
      <PerfectScrollbar>
        <div className={classes.root}>
          <Container>
            <Header />
            <div className={classes.spacer} />
            <TextSections />
            <Form />
            <Socials />
          </Container>
        </div>
      </PerfectScrollbar>
    
    </div>
  );
}
