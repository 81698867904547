const redirectToCheckout = async ({ numberOfPages }) => {
  const { loadStripe } = await import('@stripe/stripe-js');

  const lineItems = [{ price: process.env.GATSBY_PRODUCT_PAGE_PRICE, quantity: numberOfPages }];
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
  const stripe = await stripePromise;
  const { error } = await stripe.redirectToCheckout({
    lineItems,
    mode: 'payment',
    successUrl: `${window.location.href}thank-you?session_id={CHECKOUT_SESSION_ID}`,
    cancelUrl: `${window.location.href}`,
  });
  if (error) {
    console.warn('Error:', error);
  }
};

export { redirectToCheckout };
