import React from 'react';
import { makeStyles, AppBar, Toolbar, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'flex-end',
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Link href="https://devondemand.co/">
          <Typography color="textSecondary">You need a frontend developer?</Typography>
        </Link>
      </Toolbar>
    </AppBar>
  );
}
