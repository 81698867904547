import React from 'react';
import { Formik, Form } from 'formik';
import {
  FormControl,
  makeStyles,
  TextField,
  Typography,
  Grid,
  Button,
  MenuItem,
  Link,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import * as yup from 'yup';

import { sendDataToAirtable } from 'src/api/airtable';
import { redirectToCheckout } from 'src/api/stripe';

const useStyles = makeStyles(() => ({}));

const formSchema = yup.object().shape({
  typeOfPages: yup.string().required('This field is required.'),
  numberOfPages: yup
    .number()
    .min(1)
    .required('This field is required.')
    .typeError('Page number must be a number')
    .positive('Number of page must be greater than 0'),
  fileType: yup.string().required('This field is required.'),
  email: yup.string().email().required('This field is required.'),
  comments: yup.string(),
});

export default function () {
  const classes = useStyles();
  const handelSubmit = values => {
    console.log(process.env.GATSBY_AIRTABLE_TABLE_NAME);
    sendDataToAirtable(values).then(() => {
      redirectToCheckout(values);
    });
  };

  return (
    <Formik initialValues={{}} validationSchema={formSchema} onSubmit={handelSubmit}>
      {({ handleChange, errors, touched, isValid, values, isSubmitting, handleBlur }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="h5" gutterBottom>
                  Type of page you want
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="For example : Homepage + Dashboard + Contact page "
                  name="typeOfPages"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.typeOfPages && touched.typeOfPages}
                  helperText={errors.typeOfPages && touched.typeOfPages ? errors.typeOfPages : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant="h5" gutterBottom>
                  Number of pages
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Enter a number"
                  inputProps={{ min: 1 }}
                  type="number"
                  name="numberOfPages"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.numberOfPages && touched.numberOfPages}
                  helperText={errors.numberOfPages && touched.numberOfPages ? errors.numberOfPages : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant="h5" gutterBottom>
                  File you want
                </Typography>
                <TextField
                  defaultValue=""
                  variant="outlined"
                  select
                  name="fileType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.fileType && touched.fileType}
                  helperText={errors.fileType && touched.fileType ? errors.fileType : null}
                >
                  <MenuItem value="adobe">Adobe XD</MenuItem>
                  <MenuItem value="figma">Figma</MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="h5" gutterBottom>
                  Your e-mail
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Name@email.com"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                We will ask you more questions by mail to be sure we design exactly what you need
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="h5" gutterBottom>
                  Comments
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Please write all some requirements for the design team (for
              example the fonts or colors you want…"
                  multiline
                  rows={2}
                  name="comments"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.fileType && touched.fileType}
                  helperText={errors.fileType && touched.fileType ? errors.fileType : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.button}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                fullWidth
                disabled={!isValid || isSubmitting}
                type="submit"
              >
                <Typography variant="button">Get started</Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                By clicking on the button, you agree to our{' '}
                <Link href="https://docs.google.com/document/d/15VR7vyrYUeQ3WLI0M-ucpqRNuwVnnhj7XFomnYfBSU4/edit?usp=sharing" target="_blank">
                  Terms of Service
                </Link>{' '}
                and have read and acknowledge our{' '}
                <Link href="https://docs.google.com/document/d/1k9yXtT-hfvbmItpYy7nmGqeZeMdAeOzWeWTragMaLFY/edit?usp=sharing" target="_blank">
                  Privacy Policy
                </Link>
                .
              </Typography>
            </Grid>
          </Grid>
          <Backdrop open={isSubmitting} className={classes.backdrop}>
            <CircularProgress color="secondary" />
          </Backdrop>
        </Form>
      )}
    </Formik>
  );
}
