/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Grid, makeStyles, Typography, Button, Link } from '@material-ui/core';

import PortfolioCard from 'src/components/portfolioCardCard';

const useStyles = makeStyles(theme => ({
  textContainer: {
    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down('sm')]: {
      // textAlign: 'center',
    },
  },
  buttonWrapper: {
    padding: theme.spacing(5, 0),
    '& > *': {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: '#f6f7fa',
      },
    },
  },

  card: {
    backgroundColor: '#f6f7fa',
    padding: theme.spacing(0, 2),
    '& > *': {
      borderRadius: 8,
    },
  },
}));

export default function () {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { regex: "/webetter|triange|suboid/" } }) {
        group(field: relativeDirectory) {
          edges {
            node {
              relativeDirectory
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const classes = useStyles();
  return (
    <>
      <div className={classes.textContainer}>
        <Typography variant="h4" paragraph>
          What we designed so far
        </Typography>
        <Typography variant="subtitle1" paragraph>
          We will add new websites and apps as we go.
        </Typography>
      </div>
      <Grid container>
        {data.allFile.group.map((group, index) => (
          <Grid key={index} item xs={12}>
            <PortfolioCard images={group.edges} />
          </Grid>
        ))}
      </Grid>
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          disableElevation
          fullWidth
          component={Link}
          href="https://dribbble.com/DesignOnDemand"
        >
          <Typography variant="button">See more examples</Typography>
        </Button>
      </div>
    </>
  );
}
