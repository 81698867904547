/* eslint-disable import/prefer-default-export */
import Airtable from 'airtable';

const base = new Airtable({ apiKey: process.env.GATSBY_AIRTABLE_API_KEY }).base(process.env.GATSBY_AIRTABLE_BASE)(
  process.env.GATSBY_AIRTABLE_TABLE_NAME,
);

const sendDataToAirtable = ({ typeOfPages, fileType, numberOfPages, email, comments }) => {
  return base.create({
    'Type of Page': typeOfPages,
    'File Type': fileType,
    'Number of page': numberOfPages,
    Mail: email,
    Comment: comments,
  });
};

export { sendDataToAirtable };
