import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import AdobeXdLogo from 'src/assets/images/adobe.svg';
import FigmaLogo from 'src/assets/images/figma.svg';

const useStyles = makeStyles(theme => ({
  cardsContainer: {
    padding: theme.spacing(5, 0),
  },
  card: {
    borderRadius: 12,
    backgroundColor: '#232323',
    maxWidth: 268,
    margin: 'auto',
    height: '100%',
  },
  cardContent: {
    padding: theme.spacing(4, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    '& > *': {
      margin: theme.spacing(3, 0),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1.5, 0),
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1),
    },
  },
  image: {
    width: '100%',
    marginBottom: theme.spacing(-4),
    marginRight: theme.spacing(-4),
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(-3),
      marginRight: -16,
    },
  },
  textContainer: {
    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down('sm')]: {
      // textAlign: 'center',
    },
  },
}));

export default function () {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "image.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <Typography variant="h4" paragraph>
          Stand out with a unique website / app
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Imagine your favorite front developer is always there for you. No freelance hunt and missed deadlines.
        </Typography>
      </div>

      <div className={classes.cardsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <div className={classes.card}>
              <div className={classes.cardContent}>
                <Typography align="center" variant="h2" color="textSecondary">
                  XD or Figma
                </Typography>
                <AdobeXdLogo />
                <FigmaLogo />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.card}>
              <div className={classes.cardContent}>
                <div>
                  <Typography align="center" variant="h2" color="textSecondary">
                    Web (1440px)
                  </Typography>
                  <Typography align="center" variant="h2" color="textSecondary">
                    +
                  </Typography>
                  <Typography align="center" variant="h2" color="textSecondary">
                    Mobile (375px)
                  </Typography>
                </div>
                <Img fluid={data.image.childImageSharp.fluid} className={classes.image} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
