import React from 'react';
import { Grid } from '@material-ui/core';

import SEO from 'src/components/seo';

import LeftContent from 'src/features/leftContent';
import RightContent from 'src/features/rightContent';

import PHBadge from 'src/components/phBadge';

const IndexPage = () => (
  <>
    <SEO />
    <Grid container>
      <Grid item xs={12} md={5} lg={4}>
        <LeftContent />
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <RightContent />
      </Grid>
    </Grid>
    <PHBadge />
  </>
);

export default IndexPage;
