import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import CheckIcon from 'src/assets/icons/check.svg';

const useStyles = makeStyles(theme => ({
  checkSpan: {
    verticalAlign: 'text-top',
    marginRight: theme.spacing(2),
  },
  textContainer: {
    padding: theme.spacing(1, 0, 4),
  },
  text: {
    fontWeight: 300,
  },
}));

export default function () {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h1" paragraph>
        UI Design On Demand
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text}>
          <span className={classes.checkSpan}>
            <CheckIcon />
          </span>
          Delivery in 3 business days
        </Typography>
        <Typography className={classes.text}>
          <span className={classes.checkSpan}>
            <CheckIcon />
          </span>
          $149 per page
        </Typography>
        <Typography className={classes.text}>
          <span className={classes.checkSpan}>
            <CheckIcon />
          </span>
          Up to 3 revisions per page
        </Typography>
      </div>
    </div>
  );
}
