import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: 100,
    right: 20,
    [theme.breakpoints.down('sm')]: {
      position: 'sticky',
      float: 'right',
      margin: theme.spacing(0, 2),
    },
  },
}));

export default function () {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <a
        href="https://www.producthunt.com/posts/design-on-demand?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-design-on-demand"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=278139&theme=light"
          alt="Design On Demand - UI design on demand | Product Hunt"
          style={{ width: 250, height: 54 }}
          width="250"
          height="54"
        />
      </a>
    </div>
  );
}
