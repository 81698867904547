import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { makeStyles, AppBar, Toolbar, Hidden, Link, Typography } from '@material-ui/core';

import LogoText from 'src/assets/images/logo-text.svg';

const useStyles = makeStyles(theme => ({
  logoWrapper: {
    marginRight: theme.spacing(3),
    width: 40,
    [theme.breakpoints.down('sm')]: {
      width: 26,
    },
  },
  toolbar: {
    padding: 'unset',
  },
  linkWrapper: {
    marginLeft: 'auto',
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 40, maxHeight: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoText: file(relativePath: { eq: "logo-text.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoWrapper}>
          <Img fluid={data.logo.childImageSharp.fluid} />
        </div>
        <Hidden smDown>
          <div>
            <LogoText />
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.linkWrapper}>
            <Link href="https://devondemand.co/">
              <Typography color="textPrimary">You need a frontend developer?</Typography>
            </Link>
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
