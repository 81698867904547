import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, Container, Hidden } from '@material-ui/core';

import Header from './header';
import Intro from './intro';
import Portfolio from './portfolio';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 100,
    },
  },

  container: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
  },
  spacer: {
    ...theme.mixins.toolbar,
  },
}));

export default function () {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PerfectScrollbar>
        <div className={classes.container}>
          <Hidden smDown>
            <Header />
          </Hidden>
          <div className={classes.spacer} />
          <Container maxWidth="sm">
            <Intro />
            <Portfolio />
          </Container>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
