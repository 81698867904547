import React from 'react';
import { makeStyles, IconButton, Grid, Link, ButtonBase } from '@material-ui/core';

import InstagramIcon from 'src/assets/icons/instagram.svg';
import TwitterIcon from 'src/assets/icons/twitter.svg';
import DribbleIcon from 'src/assets/icons/dribbble.svg';

const useStyles = makeStyles(() => ({}));

export default function () {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={1}>
        {/* <Grid item>
          <IconButton component={Link} href="https://www.instagram.com/designondemand_xyz/" target="_blank">
            <InstagramIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton component={Link} href="" target="_blank">
            <TwitterIcon />
          </IconButton>
        </Grid> */}
        <Grid item>
          <ButtonBase component={Link} href="https://dribbble.com/DesignOnDemand" target="_blank">
            <DribbleIcon />
          </ButtonBase>
        </Grid>
      </Grid>
    </div>
  );
}
